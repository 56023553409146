
.jianli {
    position: relative;
    overflow: hidden;
    width: 100%;
    background: #fff;
    border: 1px solid #e6e8eb;
    border-top: 0 solid #e6e8eb;
    border-radius: 7px;
    box-shadow: 0 0 5px #aaaaaa;
    margin: 0 auto 100px;
}

.jianli-main {
    padding: 24px;
}

.jl-grxx {
    line-height: 2;
}

.jl-grxx strong {
    font-size: 20px;
}

.jl-title {
    line-height: 30px;
    position: relative;
    color: #2f5785;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 10px;
}
.jl-qzyx {
    font-size: 12px;
}

.jl-title::after {
    content: '';
    width: calc(100% - 90px);
    position: absolute;
    height: 1px;
    margin-left: 28px;
    background: #bdd2ea;
    opacity: .7;
    top: 19px;
    right: 0;
}

.qzyx-row {
    line-height: 2;
}

.jl-rq {
    position: absolute;
    right: 0;
    color: #4a90e2;
    top: 0;
}

.jianli-top {
    padding: 0 24px;
    height: 50px;
    border-bottom: 1px solid #e6e8eb;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.jianli-top-name {
    font-size: 18px;
}

.jianli-top-right > span {
    margin-left: 30px;
    cursor: pointer;
}

.jianli-top-right .anticon {
    margin-right: 5px;
}

.xmjy-row {
    line-height: 2;
    margin-bottom: 20px;
}

.paddingright100 {
    padding-right: 100px;
}


.jl-edit .jl-border-dashed {
    border: 1px grey dashed;
    padding: 0 10px;
    cursor: pointer;
    transition: .3s;
    min-height: 30px;
}

.jl-edit .jl-border-dashed:hover {
    background: rgba(0, 0, 0, .2);
}

.form-list-row {
    position: relative;
}

.form-list-row .anticon-minus-circle {
    position: absolute;
    right: 0px;
    top: 23px;
}

.form-list-row .title {
    line-height: 50px;
}

.ant-image {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, .3);
    margin-right: 10px;
}

.ant-image-img{
    object-fit: cover;
}

.llcs {
    position: absolute;
    bottom: 0;
    right: 5px;
    opacity: .3;
    font-size: 12px;
}


.daochuFlex {
    position: fixed;
    right: 10%;
    bottom: 150px;
    cursor: pointer;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, .5);
    line-height: 60px;
    text-align: center;
    z-index: 99;
}

.jz-wsjl, .jl-zwpj1 {
    display: none;
}

.footer {
    bottom: 0;
    width: 100%;
    text-align: center;
    height: 50px;
    background: #FFF;
}


@media print {
    body, html {
        background: none;
    }

    .jianli {
        border: none;
        box-shadow: none;
    }

    .jl-zwpj1, .jz-wsjl {
        display: block;
    }

    .header, .jz-zwpj2, .jianli-top, .jianli-main > div:nth-last-of-type(1),.footer {
        display: none;
    }

    .page {
        height: 29.7cm;
    }
    .ant-image{
        display: none;
    }
}
